import React from 'react';
import { Helmet } from 'react-helmet-async';
import GalleryCard from './GalleryCard';
import './Galleries.css';
import galleryData from '../data/galleries.json';

const Galleries = () => {
  // Create a reversed copy of the galleries array
  const reversedGalleries = [...galleryData.galleries].reverse();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    "name": "Sports Photography Galleries",
    "description": "Professional sports photography galleries and services in Fort Wayne, Indiana. Action shots for youth sports, high school sports, and more.",
    "url": "https://jaredhaagen.com/galleries",
    "about": {
      "@type": "Thing",
      "name": "Sports Photography",
      "description": "Professional sports photography services in Fort Wayne, Indiana"
    },
    "offers": {
      "@type": "Offer",
      "price": "50",
      "priceCurrency": "USD",
      "description": "Full attention for the event as well as lightly edited photos ready to share on social media or to print"
    },
    "image": reversedGalleries[0]?.images[0] || "https://jaredhaagen.com/assets/profile-2.jpg"
  };

  return (
    <>
      <Helmet>
        <title>Sports Photography Galleries - Jared Haagen</title>
        <meta name="description" content="View sports photography galleries by Jared Haagen. Professional sports photography services in Fort Wayne, Indiana. Action shots for youth sports, high school sports, and more." />
        <meta name="keywords" content="sports photography, Fort Wayne sports, youth sports photography, high school sports photography, action shots, sports galleries" />
        <meta property="og:title" content="Sports Photography Galleries - Jared Haagen" />
        <meta property="og:description" content="Professional sports photography galleries and services in Fort Wayne, Indiana. Action shots for youth sports, high school sports, and more." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://jaredhaagen.com/galleries" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sports Photography Galleries - Jared Haagen" />
        <meta name="twitter:description" content="Professional sports photography galleries and services in Fort Wayne, Indiana." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="galleries-bg">
        <div className="galleries-container">
          <div>
            <h1 className="galleries-title">Sports Photography</h1>
            <p>Hey, Fort Wayne!  I'm a dad of two active kids who LOVE playing sports.  I want to start sharing my love of sports photography with the rest of 
              our community by offering sports photography to Fort Wayne and surrounding areas.  
              Fort Wayne parents, let us come out to your child's sporting event and capture some action shots of your child or their team. 
              <br/>
              <br />
              Our services start at $50 per game,
              this will give you our full attention for the event as well as lightly edited photos ready to share on social media or to print.
              <br/>
              <br/>
               <a href="/contact">Contact me</a> to book your event! 
            </p>

            <h2>Galleries</h2>
            <p>Below are my recent galleries, click on the link to view the gallery. You will be asked for your name and email to access the gallery. The images are free for personal use only.</p>
          </div>
          <div className="galleries-grid">
            {reversedGalleries.map(gallery => (
              <GalleryCard
                key={gallery.id}
                title={gallery.title}
                date={gallery.date}
                images={gallery.images}
                link={gallery.link}
                password={gallery.password}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Galleries; 