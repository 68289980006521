import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./App.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import {
  Navigation,
  Footer,
  Home,
  Photo,
  Code,
  Contact,
  Sports,
  Galleries
} from "./components";

ReactDOM.render(
  <HelmetProvider>
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/photo" element={<Photo />} />
        <Route path="/code" element={<Code />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sports" element={<Sports />} />
        <Route path="/galleries" element={<Galleries />} />
      </Routes>
      <Footer />
    </Router>
  </HelmetProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
