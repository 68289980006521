import React from "react";
import { Helmet } from 'react-helmet-async';

function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Jared Haagen - Sports Photographer & Web Developer</title>
        <meta name="description" content="Get in touch with Jared Haagen for sports photography services or web development projects in Fort Wayne, Indiana. Connect through various social platforms." />
        <meta name="keywords" content="contact Jared Haagen, Fort Wayne photographer, web developer contact, sports photography booking, Fort Wayne Indiana" />
        <meta property="og:title" content="Contact Jared Haagen - Sports Photographer & Web Developer" />
        <meta property="og:description" content="Get in touch with Jared Haagen for sports photography services or web development projects in Fort Wayne, Indiana." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://jaredhaagen.com/contact" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Jared Haagen - Sports Photographer & Web Developer" />
        <meta name="twitter:description" content="Get in touch with Jared Haagen for sports photography services or web development projects in Fort Wayne, Indiana." />
      </Helmet>
      <div className="contact-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-9 col-xs-9 content">
              <h1>Contact me!</h1>
              <p>I could add a form here to email me, but instead I'm going to leave some links to more modern contact methods... </p>
              <p>
                <a href="https://github.com/jhaagen">Github</a>
                <br/>
                <a href="https://codepen.io/jhaagen">Code Pen</a>
                <br/>
                <a href="https://www.linkedin.com/in/jaredhaagen/">LinkedIn</a>
                <br/>
                <a href="https://www.google.com/search?q=%22jared+haagen%22">Google</a>
              </p>
              <p>If you can't find me here, <a href="mailto:jared.haagen@gmail.com">email me</a> or maybe our paths will cross by chance in the future!</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
