import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import emailjs from '@emailjs/browser';
import { EMAILJS_CONFIG } from '../config/emailjs';
import './GalleryCard.css';

const GalleryCard = ({ title, date, images, link, password }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsTransitioning(false);
      }, 500); // Match this with CSS transition duration
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleModalSubmit = async (formData) => {
    try {
      // Verify password with exact string comparison
      if (formData.password.trim().toLowerCase() !== password.trim().toLowerCase()) {
        alert('Incorrect password. Please try again.');
        return;
      }

      // Send email using EmailJS
      await emailjs.send(
        EMAILJS_CONFIG.SERVICE_ID,
        EMAILJS_CONFIG.TEMPLATE_ID,
        {
          to_name: 'Jared',
          from_name: formData.name,
          from_email: formData.email,
          gallery_title: title,
          gallery_link: link,
          provided_password: formData.password
        },
        EMAILJS_CONFIG.PUBLIC_KEY
      );

      // Close the modal first
      setIsModalOpen(false);
      
      // Create a temporary link element
      const tempLink = document.createElement('a');
      tempLink.href = link;
      tempLink.target = '_blank';
      tempLink.rel = 'noopener noreferrer';
      
      // Append to body, click, and remove
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('There was an error submitting your request. Please try again.');
    }
  };

  return (
    <>
      <div className="gallery-card" onClick={handleCardClick}>
        <div className="gallery-card-hero">
          <img 
            src={images[currentImageIndex]} 
            alt={`${title} - ${currentImageIndex + 1}`}
            className={`gallery-card-image ${isTransitioning ? 'fade-out' : 'fade-in'} ${!isImageLoaded ? 'loading' : ''}`}
            loading="lazy"
            onLoad={() => setIsImageLoaded(true)}
          />
          {!isImageLoaded && (
            <div className="image-placeholder">
              <div className="spinner"></div>
            </div>
          )}
        </div>
        <div className="gallery-card-content">
          <h3 className="gallery-card-title">{title}</h3>
          <p className="gallery-card-date">{date}</p>
          <button className="gallery-card-link">
            View Gallery
          </button>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
        galleryTitle={title}
      />
    </>
  );
};

export default GalleryCard; 