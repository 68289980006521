import React from "react";
import { Helmet } from 'react-helmet-async';

function Home() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Jared Haagen",
    "url": "https://jaredhaagen.com",
    "image": "https://jaredhaagen.com/assets/profile-2.jpg",
    "sameAs": [
      "https://github.com/jhaagen",
      "https://codepen.io/jhaagen",
      "https://www.linkedin.com/in/jaredhaagen/"
    ],
    "jobTitle": "Sports Photographer & Web Developer",
    "worksFor": {
      "@type": "Organization",
      "name": "Sites & Scenes"
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Fort Wayne",
      "addressRegion": "IN",
      "addressCountry": "US"
    },
    "knowsAbout": ["Sports Photography", "Web Development", "Front-end Development", "Real Estate Photography", "Portrait Photography", "Drone Photography"]
  };

  return (
    <>
      <Helmet>
        <title>Jared Haagen - Sports Photographer & Web Developer in Fort Wayne</title>
        <meta name="description" content="Jared Haagen is a freelance sports photographer and web developer based in Fort Wayne, Indiana. Specializing in sports photography, web development, and creating memorable moments." />
        <meta name="keywords" content="sports photographer, web developer, Fort Wayne, Indiana, freelance photographer, sports photography, web development" />
        <meta property="og:title" content="Jared Haagen - Sports Photographer & Web Developer" />
        <meta property="og:description" content="Freelance sports photographer and web developer based in Fort Wayne, Indiana. Capturing moments and building digital experiences." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://jaredhaagen.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Jared Haagen - Sports Photographer & Web Developer" />
        <meta name="twitter:description" content="Freelance sports photographer and web developer based in Fort Wayne, Indiana." />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="intro-bg">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-sm-9 col-xs-9 intro-content justify-content-start">
                <h1>Hi! I'm Jared Haagen.</h1>
                <p>I am a freelance sports photographer and web developer at my company called Sites &amp; Scenes.
                  <br/> I live in Fort Wayne, Indiana and work at <a href="http://www.sweetwater.com">Sweetwater Sound</a>.</p>
                <p>When I'm not driving my 2 kids to their sports, I'm either riding bikes, detailing cars, or finding yet another hobby.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
