import React from "react";
import { Helmet } from 'react-helmet-async';

function Code() {
  return (
    <>
      <Helmet>
        <title>Web Development Services - Jared Haagen</title>
        <meta name="description" content="Professional web development services in Fort Wayne, Indiana. 20+ years of experience in front-end development, performance optimization, and creating accessible web applications." />
        <meta name="keywords" content="web developer Fort Wayne, front-end development, web development, performance optimization, ADA compliance, Fort Wayne Indiana" />
        <meta property="og:title" content="Web Development Services - Jared Haagen" />
        <meta property="og:description" content="Professional web development services in Fort Wayne, Indiana. 20+ years of experience in front-end development and creating accessible web applications." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://jaredhaagen.com/code" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Web Development Services - Jared Haagen" />
        <meta name="twitter:description" content="Professional web development services in Fort Wayne, Indiana. 20+ years of experience." />
      </Helmet>
      <div className="coding-bg">
        <div className="container">
          <div className="row align-items-center my-5">
            <div className="col-lg-12 code-segment">
              <h1 className="font-weight-light">Web Development</h1>
              <p>
                I've been in the web development space for 20+ years at this point. So, listing out what I've done is a waste of both of our time. 
                <br/>
                So instead, below are a few things I'm passionate about.
              </p>
              <br/>
              <br/>
              <h2>Front-end Development</h2>
              <p>
                HTML5 / CSS3 yeah sure. I love them. But there's more to it. Performance, ADA compliance, maintainability are all a huge part of front-end development.
              </p>
              <br/>
              <br/>
              <h2>The Team</h2>
              <p>
                I can't remember how many times I've said "I care more about you than the work that needs to be done." Taking care of co-workers, clients, and loved ones are very important to me.
                <br/>
                I've learned taking care of the people around you makes life better for everyone, and magically gets the work done as well.
              </p>
              <br/>
              <br/>
              <h2>A Few Examples</h2>
              <div className="content-left">
                <div>
                  <p><a href="http://3e-industries.com">3E&WWG</a> is a great client that gave me the opportunity to build their website as well as fill it with photos and videos of their amazing work!</p>
                  <p>This website is heavy on the visual components as the business realized that building amazing backyards is difficult to communicate without these photos and videos.<br/>
                  Some of the larger projects they have completed have a project page dedicated to it, which allows new customers to understand what went into building out that project</p>
                  <p>
                    Along with the public site, I also built them a custom project management tool that allows them to plan out their day/weeks with much greater efficiency.
                  </p>
                  <p>The site is built with the understanding that the majority of their customers are looking at their site on mobile devices. So, a small screen design was prioritized </p>
                </div>
                <div>
                  <img src="/assets/3e-website.jpg" alt="3e-industries and Woodland Water Gardens website showcase" />
                </div>
              </div>
              <div className="content-right">
                <div>
                  <p>BC Surface Supply is a project that is currently in progress, ultimately will have a custom kitchen designer as well as a huge amount of information on the cabinets and countertops they supply and install.</p>
                  <p>This kitchen installer is located in Fort Wayne, but serves about a 8 hour driving radius. Most of their installs are in apartment buildings, or multifamily homes. But they don't shy away from helping individual homeowner either!</p>
                  <p>This project should be wrapping up this spring, and will utilize the Azure Static Website, with a React based site running on it. All the content will be sideloaded via json files to ensure fast editability and code reilability.</p>
                  <br/><br/>
                </div>
                <div>
                  <img src="/assets/bc-supply.jpg" alt="BC Surface Supply website showcase" />
                </div>
              </div>
              <h2>The Cloud</h2>
              <p>
                If you are running your current website from a physical server and paying through the nose for hosting, let's talk. I can probably save you money. This site for example is running on a free hosting plan.
                <br/>
                The ability to use serverless technology can process large amounts of data quickly, or small amounts really inexpensively. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Code;
